import React, { useEffect } from "react";
import {
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardBody,
	MDBIcon,
	MDBBtn,
	MDBContainer,
} from "mdbreact";
import SEO from "../components/SEO/Seo";
import Layout from "../Layouts/MasterLayout/MasterLayout";

export default function ContatoPage() {
	// Lógica executada no frontend quando o componente é carregado.
	useEffect(() => {
		if(typeof window !== 'undefined' && window.document && document.getElementById("rd-form-kub9g7u9") === null) {
			const script = document.createElement("script");
			script.src = "https://d335luupugsy2.cloudfront.net/js/rdstation-forms/stable/rdstation-forms.min.js";
			script.async = true;
			script.onload = () => {
				new RDStationForms(
					"graduacao-registro-de-interesse-f5696547c4e90aac7f05",
					"UA-32345233-1"
				).createForm();
			};
			document.body.appendChild(script);	
		}
	});

	return (
		<Layout>
			<SEO
				title="Fale com a Feevale"
				description="Dúvidas sobre o vestibular ou os cursos? Fale com o time da Universidade Feevale!"
			/>

			<MDBContainer>
				<section className="mt-5">
					<h2 className="h1-responsive font-weight-bold text-center my-5 text-secondary text-uppercase">
						Fale com a gente
					</h2>
					<MDBRow>
						<MDBCol lg="5" className="lg-0 mb-4 mb-lg-0">
							<MDBCard>
								<MDBCardBody>
									<div
										role="main"
										id="graduacao-registro-de-interesse-f5696547c4e90aac7f05"
									></div>
								</MDBCardBody>
							</MDBCard>
						</MDBCol>
						<MDBCol lg="7">
							<div
								id="map-container"
								className="rounded z-depth-1-half map-container"
								style={{ height: "400px" }}
							>
								<iframe
									src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6933.3819795897625!2d-51.12111592203397!3d-29.670740055946062!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x951943931e5bed2f%3A0xc0d78c5c5ba599b5!2sUniversidade%20Feevale!5e0!3m2!1spt-BR!2sbr!4v1633267270111!5m2!1spt-BR!2sbr"
									title="Universidade Feevale"
									width="100%"
									height="100%"
									style={{ border: 0 }}
									frameBorder="0"
									loading="lazy"
								></iframe>
							</div>
							<br />
							<MDBRow className="text-center">
								<MDBCol md="12">
									<MDBBtn
										block
										tag="a"
										color="default"
										className="accent-1"
										target="blank"
										href="https://whts.co/feevale-graduacao"
									>
										<MDBIcon icon="fab whatsapp" /> Chamar no WhatsApp
									</MDBBtn>
								</MDBCol>
								<MDBCol md="6" className="mt-2">
									<MDBBtn
										block
										tag="a"
										color="default"
										className="accent-1"
										href="tel:555135868800"
									>
										(51) 3586.8800
									</MDBBtn>
								</MDBCol>
								<MDBCol md="6" className="mt-2">
									<MDBBtn
										block
										tag="a"
										color="default"
										className="accent-1"
										href="mailto:falecomafeevale@feevale.br"
									>
										falecomafeevale@feevale.br
									</MDBBtn>
								</MDBCol>
							</MDBRow>
						</MDBCol>
					</MDBRow>
				</section>
			</MDBContainer>
		</Layout>
	);
}
